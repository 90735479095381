import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import './App.css';

function MovieDetail({ movie }) {
  const [selectedEpisode, setSelectedEpisode] = useState(null);
  const [currentEpisodeUrl, setCurrentEpisodeUrl] = useState('');

  const handleShare = () => {
    const shareUrl = `${window.location.origin}?vod_id=${movie.vod_id}`;
    navigator.clipboard.writeText(shareUrl).then(() => {
      alert('分享信息复制成功，赶紧去分享吧！');
    }).catch((err) => {
      console.error('复制失败: ', err);
      alert('复制链接失败，请重试。');
    });
  };

  const handleEpisodeClick = useCallback((playUrl) => {
    const decodedUrl = decodeURIComponent(playUrl);
    setSelectedEpisode(`https://bofang.ikdmjx.com/?url=${decodedUrl}`);
    setCurrentEpisodeUrl(playUrl);
  }, []);

  useEffect(() => {
    if (movie.vod_play_url) {
      const firstEpisode = movie.vod_play_url.split('#')[0].split('$')[1];
      handleEpisodeClick(firstEpisode);
    }
  }, [movie, handleEpisodeClick]);

  return (
    <div className="movie-detail">
      <h2>{movie.vod_name}</h2>
      <button onClick={handleShare} className="share-button">
          <span className="share-icon">&#x1F4CB;</span> 分享
        </button>
      {selectedEpisode && (
        <div className="player">
          <iframe
            src={selectedEpisode}
            width="100%"
            height="400"
            frameBorder="0"
            allowFullScreen
            title="Movie Player"
            border="0" 
            marginwidth="0" 
            marginheight="0" 
            scrolling="no"
          ></iframe>
        </div>
      )}
      
      {movie.vod_play_url && (
        <div className="episodes-container">
          <h3>剧集列表</h3>
          <div className="episodes">
            {movie.vod_play_url.split('#').map((episode, index) => {
              const [name, url] = episode.split('$');
              return (
                <button 
                  key={index} 
                  onClick={() => handleEpisodeClick(url)}
                  className={url === currentEpisodeUrl ? 'active' : ''}
                >
                  {name}
                </button>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

function App() {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedMovie, setSelectedMovie] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const isFirstRender = useRef(true);

  
  const handleSearch = useCallback(async (term = '') => {
    setLoading(true);
    setError('');
    setSearchResults([]);
    setSelectedMovie(null);
    try {
      const response = await axios.get(`https://movie.91aitv.com/api.php/provide/vod/?ac=list&wd=${encodeURIComponent(term)}`);
      if (response.data.list && response.data.list.length > 0) {
        // 异步加载每个搜索结果的详细信息
        response.data.list.forEach(async (movie) => {
          try {
            const detailResponse = await axios.get(`https://movie.91aitv.com/api.php/provide/vod/?ac=detail&ids=${movie.vod_id}`);
            const detailedMovie = detailResponse.data.list ? detailResponse.data.list[0] : movie;
            setSearchResults(prevResults => [...prevResults, detailedMovie]);
          } catch (error) {
            console.error('获取详情出错:', error);
            setSearchResults(prevResults => [...prevResults, movie]);
          }
        });
      } else {
        setError('未找到相关影视资源。');
      }
    } catch (error) {
      console.error('搜索出错:', error);
      setError('搜索时发生错误，请稍后再试。');
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchMovieDetails = useCallback(async (vodId) => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.get(`https://movie.91aitv.com/api.php/provide/vod/?ac=detail&ids=${vodId}`);
      if (response.data.list && response.data.list.length > 0) {
        setSelectedMovie(response.data.list[0]);
      } else {
        setError('Movie details not found.');
      }
    } catch (error) {
      console.error('Error fetching movie details:', error);
      setError('An error occurred while fetching movie details.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const vodId = urlParams.get('vod_id');
    if (vodId) {
      fetchMovieDetails(vodId);
    } else if (isFirstRender.current) {
      handleSearch('');
      isFirstRender.current = false;
    }
  }, [handleSearch, fetchMovieDetails]);



  const handleMovieClick = (movie) => {
    setSelectedMovie(movie);
  };

  const handleSearchClick = () => {
    handleSearch(searchTerm);
  };


  const handleCloseDetail = () => {
    setSelectedMovie(null);
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>十分阳光影院</h1>
        <div className="search-container">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="输入影视名称..."
          />
          <button onClick={handleSearchClick}>搜索</button>
        </div>
      </header>
      <main>
        {loading && <p className="loading">加载中...</p>}
        {error && <p className="error">{error}</p>}
        {selectedMovie ? (
          <MovieDetail movie={selectedMovie} onClose={handleCloseDetail} />
        ) : (
          <div className="search-results">
            {searchResults.map((movie) => (
              <div key={movie.vod_id} className="movie-item" onClick={() => handleMovieClick(movie)}>
                <img src={movie.vod_pic} alt={movie.vod_name} />
                <h3>{movie.vod_name}</h3>
              </div>
            ))}
          </div>
        )}
      </main>
    </div>
  );
}

export default App;